import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip, Alert } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import axios from "axios";


export default function GenerateCode(props) {
  const ref = React.useRef();
  const formData = props.formData;
  const clientName = formData["clientName"];
  const gistUrl = props.gistUrl;
  let path,
    displayCode = [];
  let vp = [];
  // let urlGist = [];
  const adTypelistGc = [
    "ub-anchor-da",
    "ub-popup-da",
    "ub-inimage-da",
    "ub-left-ad-da",
    "ub-right-ad-da",
    "ub-balloon-da",
  ];
  const adTypeListHb = [
    "div-ub-1",
    "ub-anchor",
    "ub-anchor",
    "ub-top-anchor",
    "ub-popup",
    "ub-inimage",
    "ub-left-ad",
    "ub-right-ad",
    "ub-balloon",
    "ub-interstitial",
    "ub-siderail",
    "ub-siderail_1",
    "ub-siderail_2",
    "ub-siderail_3",
    "ub-rewarded"
  ];
  const [divsHb, setDivsHb] = useState([]);
  const [divsGc, setDivsGc] = useState([]);

  useEffect(() => {
    let newDivsHb = [...divsHb];
    let newDivsGc = [...divsGc];

    if (props.product === "GenericCube") {
        setDivsGc([]);
        const adMappingsGc =
            formData?.additionalDetailsGenericCube?.ad_mappings ?? [];
        //   adMappingsGc.forEach((data) => {
        //       if (
        //           !adTypelistGc.includes(data.adUnitId) &&
        //           !newDivsGc.includes(data.adUnitId)
        //       ) {
        //           newDivsGc.push(data.adUnitId);
        //       }
        //   });

        const filteredAdMappingsGc = adMappingsGc.filter(
            (data) => !adTypelistGc.includes(data.adUnitId)
        );
        filteredAdMappingsGc.forEach((data,index) => {
            if (
                !newDivsGc.includes(data.adUnitId)
            ) {
                const currentId = data.adUnitId;
                // Check if the current index in divGc matches the currentId
                if (newDivsGc[index] !== currentId) {
                    // If mismatch, update the divGc array at this index
                    newDivsGc[index] = currentId;
                } else {
                    // If match, push the currentId to the end of the array
                    newDivsGc.push(currentId);
                }
            }
        });
        setDivsGc(newDivsGc);
    }
    if (props.product === "HeaderBidding") {
      setDivsHb([]);
      const adMappingsHb =
        formData?.additionalDetailsHeaderBidding?.mappings_full_hb_config ?? [];

    //   adMappingsHb.forEach((data) => {
    //     if (
    //       !adTypeListHb.some((adType) => data.adId.includes(adType)) &&
    //       !data?.targetDiv?.selector
    //     ) {
    //       let id = data?.childwidth_classname?.split("#")?.pop();
    //       if (data.adId.includes("ub-parallax") && !newDivsHb.includes(id)) {
    //         newDivsHb.push(id);
    //       } else if (!newDivsHb.includes(data.adId) && !(data.adId.includes("ub-parallax"))) {
    //         newDivsHb.push(data.adId);
    //       }
    //     }
    //   });

    const filteredAdMappingsHb = adMappingsHb.filter(
        (data) =>
            !adTypeListHb.some((adType) => data.adId.includes(adType)) &&
            !data?.targetDiv?.selector
    );
    filteredAdMappingsHb.forEach((data,index) => {
            let id = data?.childwidth_classname?.split("#")?.pop();
            if (data.adId.includes("ub-parallax") && !newDivsHb.includes(id)) {
                newDivsHb.push(id);
            } else if (
                !newDivsHb.includes(data.adId) &&
                !data.adId.includes("ub-parallax")
            ) {
                 const currentId = data.adId;
                 // Check if the current index in divHB matches the currentId
                 if (newDivsHb[index] !== currentId) {
                     // If mismatch, update the divHB array at this index
                     newDivsHb[index] = currentId;
                 } else {
                     newDivsHb.push(data.adId);
                 }
            }
    });
      setDivsHb(newDivsHb);
    }
  }, [props.formData, props.product]);
  let divs = (props.product === "HeaderBidding") ? divsHb : (props.product === "GenericCube") ? divsGc : "";
// console.log("Hb",divsHb,"Gc",divsGc)

  switch (props.product) {
    case "VideoPlayer":
      path = "https://cdn.unibotscdn.com/ubplayer/player.js";
      displayCode.push(`window.unibots = window.unibots || { cmd: [] };`);
      displayCode.push(
        `unibots.cmd.push(()=>{ unibotsPlayer("${clientName}"); });`
      );
      vp.push(`<div id="div-ub-${clientName}">`);
      vp.push(`</div>`);
      break;
    case "HeaderBidding":
      path = "https://cdn.unibots.in/headerbidding/common/hb.js";
      displayCode.push(`window.unibotshb = window.unibotshb || { cmd: [] };`);
      displayCode.push(`unibotshb.cmd.push(()=>{ ubHB("${clientName}"); });`);
      break;
    case "NewsBot":
      path = "https://cdn.unibots.in/newsbot/common/script.js";
      displayCode.push(`window.unibotsna = window.unibotsna || { cmd: [] };`);
      displayCode.push(`unibotsna.cmd.push("${clientName}");`);
      break;
    case "EventsWidget":
      path = "https://cdn.unibots.in/eventswidget/common/script.js";
      displayCode.push(`window.unibotsew = window.unibotsew || { cmd: [] };`);
      displayCode.push(`unibotsew.cmd.push("${clientName}");`);
      break;
    case "CricketWidget":
      path = "https://cdn.unibots.in/cricketwidget/common/script.js";
      displayCode.push(`window.unibotscw = window.unibotscw || { cmd: [] };`);
      displayCode.push(`unibotscw.cmd.push("${clientName}");`);
      break;
    case "GenericCube":
      path = "https://cdn.unibots.in/genericcube/common/script.js";
      displayCode.push(
        `window.unibotsDirectAds = window.unibotsDirectAds || { cmd: [] };`
      );
      displayCode.push(`unibotsDirectAds.cmd.push("${clientName}");`);
      break;
    case "DirectCampaign":
      path = "https://cdn.unibots.in/directcampaign/common/script.js";
      displayCode.push(`window.ubCampaign = window.ubCampaign || { cmd: [] };`);
      displayCode.push(`ubCampaign.cmd.push("${clientName}");`);
      break;
    case "AMPGists":

      break;
    default:
      path = "";
      displayCode.push(``);
      displayCode.push(``);
      break;
  }

  const [showAlert, setShowAlert] = React.useState(false);
  const handleGenerateCodeClose = () => {
    props.handleGenerateCodeClose("D" + props.index);
  };

  const handleCopyToClipboard = () => {
    let code = ref.current.innerText;
    navigator.clipboard.writeText(code);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };
  const prodFormat = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  if (!clientName) {
    return (
      <Dialog
        open={props.open}
        fullWidth="true"
        maxWidth="md"
        onClose={handleGenerateCodeClose}
      >
        <DialogContent>
          <DialogContentText>
            Fill in the Additional Product details to Generate the{" "}
            {prodFormat(props.product)} Code
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleGenerateCodeClose}
            variant="outlined"
            color="error"
            autoFocus
            endIcon={<CancelIcon />}
          >
            <Box sx={{ height: "22px" }}> Cancel</Box>
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={props.open}
        fullWidth="true"
        maxWidth="md"
        onClose={handleGenerateCodeClose}
      >
        {showAlert ? (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Copied!
            </Alert>
          </span>
        ) : (
          <></>
        )}
        <DialogTitle>
          <Chip
            color="info"
            label={`Generated ${prodFormat(props.product)} Code`}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              borderRadius: "10px",
              padding: "30px",
            }}
          >
            <code ref={ref}>
              {props.dfp ? (
                <>
                  <span
                    style={{ color: "#f8c28f" }}
                  >{`<!-- Place this in your head or body tag -->`}</span>
                  <br />
                  {props.product === "VideoPlayer" ? `<script async src="https://cdn.unibotscdn.com/DFP/${clientName}.js"></script>` : props.product === "HeaderBidding" ? `<script async src="https://cdn.unibots.in/headerbidding/DFP/${clientName}.js"></script>` : ''}
                </>
              ) : (
                <>
                  {(props.product !== "AMPGists") ?
                  props.code && (
                    <>
                      <span
                        style={{ color: "#f8c28f" }}
                      >{`<!-- Place this in your head tag -->`}</span>
                      <br />
                      {`<script async src="${path}"></script>`}
                      <br />
                      <span
                        style={{ color: "#f8c28f" }}
                      >{`<!-- Place this in your body tag -->`}</span>
                      <br />
                      {props.product === "VideoPlayer"
                        ? `<div id="div-ub-${clientName}">`
                        : ``}
                      {props.product === "VideoPlayer" ? <br /> : <></>}
                      &nbsp;&nbsp;&nbsp; {`<script>`}
                      <br />
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      {displayCode[0]}
                      <br />
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      {displayCode[1]}
                      <br />
                      &nbsp;&nbsp;&nbsp;{`</script>`}
                      <br />
                      {props.product === "VideoPlayer" ? `</div>` : ``}
                    </>
                  ) :(<></>)
                  }    
                  {(props.product === "GenericCube" ||
                    props.product === "HeaderBidding") &&
                  divs.length > 0 &&
                  !props.code ? (
                    <>
                      <span
                        style={{ color: "#f8c28f" }}
                      >{`<!-- Place these divs where you want to see the ads in your body tag -->`}</span>
                      {divs.map((id) => {
                        return (
                          <>
                            <br />
                            &nbsp;&nbsp;&nbsp;
                            {`<div id="${id}"></div>`}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    ``
                  )}
                  {(props.product === "AMPGists") ?
                  <>
                    {gistUrl}
                  </>
                  :
                  <>
                  </>
                  }
                </>
              )}
            </code>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCopyToClipboard}>
            Copy
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleGenerateCodeClose}
            autoFocus
            endIcon={<CancelIcon />}
          >
            <Box sx={{ height: "22px" }}> Cancel</Box>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
