import { createTheme, createStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import TaskComponent from "./components/Tasks/TaskComponent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Layout from "./components/sidebar/Layout";
import ClientComponent from "./components/Clients/ClientComponent";
import SellerComponent from "./components/Sellers/SellerComponent";
import Login from "./components/Authentication/Login";
import Leads from "./components/Leads/Leads";
import QuizTwiz from "./components/QuizTwiz/QuizTwiz";
import { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import Alert from "@mui/material/Alert";
import { updatePerson } from "./redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import AccessDenied from "./utils/AccessDenied";
import Users from "./components/Users/Users";
import Subdomains from "./components/Subdomains/Subdomains";
// import Users from "./components/Admin/Users";
// import AdminPanel from "./components/Admin/AdminPanel";
function App() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [clientFlag, setClientFlag] = useState(false);
  const [sellerFlag, setSellerFlag] = useState(false);
  // const cookieRef = useRef(document.cookie.split("=").pop());
  const [alert, setAlert] = useState({
    message: "",
    severity: "",
    show: true,
  });
  const [clientreset, setclientreset] = useState(false);
  const clientEdited = (status) => {
    setclientreset(status);
  };
  const userData = useSelector((state) => state.userData);
  const showAlertMsg = (msg, sev, time = 4000) => {
    setAlert((prev) => {
      return {
        ...prev,
        message: msg,
        severity: sev,
        show: true,
      };
    });

    setTimeout(() => {
      setAlert((prev) => {
        return {
          ...prev,
          message: "",
          severity: "",
          show: false,
        };
      });
    }, time);
  };
  function changeClientFlag() {
    setClientFlag(!clientFlag);
  }
  function changeSellerFlag() {
    setSellerFlag(!sellerFlag);
  }
  function setCookieStatus(newValue) {
    setCookie("access_token", newValue);
  }

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#FAFAFA",
      },
      secondary: {
        main: "#121212",
      },
      text: {
        primary: "#000",
        secondary: "#fff",
      },
      action: {
        active: "#fff",
      },
    },
    overrides: {
      MuiPaper: createStyles({
        root: {
          color: "#fff",
          backgroundColor: "#121212",
        },
      }),
      MuiList: createStyles({
        root: {
          maxHeight: "300px",
        },
      }),
    },
  });
  useEffect(() => {
    authenicateToken(document.cookie.split("=").pop());
  }, [document.cookie.split("=").pop()]);
  // useEffect(() => {
  //   authenicateToken(cookieRef.current);
  // }, [cookieRef.current]);

  async function authenicateToken(token) {
    let result = false;
    const tokenBody = { access_token: token };
    const response = await fetch(process.env.REACT_APP_API_URL + "login/auth", {
      method: "POST",
      body: JSON.stringify(tokenBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const out = await response.json();
    result = out.loggedin;
    dispatch(updatePerson(out.data ?? {}));
    if (!result) {
      setCookieStatus("");
      return;
    }
  }
  if (cookies.access_token) {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />

            <div className="App">
                <Router>
                    <Layout
                        clientEdited={clientEdited}
                        clientreset={clientreset}
                        userName={cookies.userName}
                        changeClientFlag={changeClientFlag}
                        changeSellerFlag={changeSellerFlag}
                        setCookieStatus={setCookieStatus}
                    >
                        {alert.show ? (
                            <span
                                style={{
                                    position: "fixed",
                                    width: "70%" /*zIndex: 1000*/,
                                }}
                            >
                                <Alert severity={alert.severity} sx={{ mb: 2 }}>
                                    {alert.message}
                                </Alert>
                            </span>
                        ) : (
                            <></>
                        )}
                        <Switch>
                            <Route exact path="/">
                                <Home showAlertMsg={showAlertMsg} />
                            </Route>
                            <Route exact path="/tasks">
                                <TaskComponent showAlertMsg={showAlertMsg} />
                            </Route>
                            {/* <Route exact path="/inventory">
                                <InventoryComponent />
                            </Route> */}
                            <Route exact path="/clients">
                                <ClientComponent
                                    clientEdited={clientEdited}
                                    showAlertMsg={showAlertMsg}
                                    clientFlag={clientFlag}
                                />
                            </Route>
                            <Route exact path="/sellers">
                                {false ? (
                                    <SellerComponent
                                        showAlertMsg={showAlertMsg}
                                        sellerFlag={sellerFlag}
                                    />
                                ) : (
                                    <AccessDenied />
                                )}
                            </Route>
                            <Route exact path="/leads">
                                <Leads showAlertMsg={showAlertMsg} />
                            </Route>
                            {/* <Route exact path="/quiztwiz">
                                <QuizTwiz showAlertMsg={showAlertMsg} />
                            </Route> */}
                            {/* <Route exact path="/arbitrages">
                                <Subdomains showAlertMsg={showAlertMsg} />
                            </Route> */}

                            {userData?.user_type === "admin" && (
                                <Route exact path="/users">
                                    <Users showAlertMsg={showAlertMsg} />
                                </Route>
                            )}
                        </Switch>
                    </Layout>
                </Router>
            </div>
        </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="App">
          {alert.show ? (
            <span style={{ position: "fixed", width: "90%", margin: "50px" }}>
              <Alert severity={alert.severity} sx={{ mb: 2 }}>
                {alert.message}
              </Alert>
            </span>
          ) : (
            <></>
          )}
          <Login
            showAlertMsg={showAlertMsg}
            token={cookies}
            setCookieStatus={setCookieStatus}
          />
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
