const productAdditionalFields = {
  HeaderBidding: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "PREBID_TIMEOUT",
      label: "PREBID TIMEOUT",
      type: "Input",
      default: 2000,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "FAILSAFE_TIMEOUT",
      label: "FAILSAFE TIMEOUT",
      type: "Input",
      default: 3000,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "REFRESH_TIMEOUT",
      label: "REFRESH TIMEOUT",
      type: "Input",
      default: 60000,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "Popup_timer",
      label: "Popup Timer",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
      isNum: true,
    },
    {
      "name": "popupAutoCloseTimer",
      "label": "Popup Auto Close Timer",
      "type": "Input",
      "default": 5,
      "selectOptions": null,
      "isBool": false,
      "isRequired": false,
      "isNum": true
    },
    {
      name: "publisherDomain",
      label: "Publisher Domain",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "Data_adclient",
      label: "Data adclient",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "sid",
      label: "seller's id",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "Load_Percentage",
      label: "Load Percentage",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "APS_pubID",
      label: "APS pubID",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
      line: true,
    },
    {
      name: "NETWORK_IDs",
      label: "NETWORK IDs",
      type: "MultiInputField",
      default: [],
      selectOptions: null,
      isBool: false,
      isRequired: false,
      line: true,
    },
    {
      name: "Unblock_Ads",
      label: "Unblock Ads",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "full_campaign",
      label: "full campaign",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "LazyLoad",
      label: "LazyLoad",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Popup_autoclose",
      label: "Popup autoclose",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "isAPS",
      label: "isAPS",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Percentage_Load",
      label: "Percentage Load",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Infinite_Scroll",
      label: "Infinite Scroll",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Load_PLscript",
      label: "Load PLscript",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CHECK_BLOCKED_PAGES",
      label: "CHECK BLOCKED PAGES",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "forceSlotInit",
      label: "forceSlot",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "fillRate_100",
      label: "Fill Rate 100",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Edit_Warning",
      label: "Edit Warning",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "nativeEnable",
      label: "Enable Native Ads",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "outstreamEnbable",
      label: "Enable Outstream",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "MultiNw_Ad",
      label: "MultiNetwork Ads",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ScriptDisabled",
      label: "Disable Script",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CUSTOM_ADS_DIV",
      label: "Custom Ads Div",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      line: true,
      textbox: true,
    },
    {
      name: "CURRENCY_CONVERSION",
      label: "CURRENCY CONVERSION",
      type: "objectgroup",
      default: {},
      fields: [
        {
          name: "enable",
          label: "enable",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
          isRequired: true,
          saveType: "bool",
        },
        {
          name: "adServer_Currency",
          label: "adServer_Currency",
          type: "Input",
          default: "INR",
          selectOptions: null,
          isBool: false,
          isRequired: true,
        },
        {
          name: "granularity_Multiplier",
          label: "granularity_Multiplier",
          type: "Input",
          default: 83,
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "number",
        },
        {
          name: "defaultRates",
          label: "defaultRates",
          type: "Input",
          default: 83,
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "number",
        },
        {
          name: "conversionRate_Url",
          label: "conversionRate_Url",
          type: "Input",
          default:
            "https://cdn.jsdelivr.net/gh/prebid/currency-file@1/latest.json",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
      ],
      // selectOptions: ["true", "false"],
      isRequired: false,
      addButton: false,
    },
    {
      name: "hb_full_common_bidders",
      label: "HB FULL COMMON BIDDERS",
      type: "objectgroup",
      fields: [
        {
          name: "bidder",
          label: "bidder",
          type: "Dropdown",
          default: "",
          selectOptions: [
            "33across",
            "adtelligent",
            "adyoulike",
            "appnexus",
            "emx_digital",
            "nobid",
            "oftmedia",
            "onetag",
            "rhythmone",
            "rubicon",
            "smartadserver",
            "rtbhouse",
            "smilewanted",
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "Labeltype",
          label: "Label type",
          type: "radio",
          default: "none",
          selectOptions: ["labelAny", "labelAll", "none"],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "params",
          label: "params",
          type: "Input",
          default: {},
          selectOptions: {
            "33across": ["siteId", "productId|siab"],
            adtelligent: ["aid"],
            adyoulike: ["placement"],
            appnexus: ["placementId"],
            emx_digital: ["tagid"],
            nobid: ["siteId"],
            oftmedia: ["placementId"],
            onetag: ["pubId|60c32c42465aac2"],
            rhythmone: ["placementId"],
            rubicon: [
              "accountId|23976",
              "siteId|400078",
              "bidonmultiformat|true",
              "zoneId",
            ],
            smartadserver: [
              "networkId|3815",
              "formatId|118103",
              "siteId",
              "pageId",
            ],
            rtbhouse: [
              "region|prebid-asia",
              "publisherId|3BzxjHyXYZUUMW40Zmw0",
            ],
            smilewanted: ["zoneId|unibots.com_hb_display"],
          },
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
    },
    {
      name: "mappings_full_hb_config",
      label: "MAPPINGS FULL HB CONFIG",
      type: "objectgroup",
      fields: [
        {
          name: "adUnitDisplay",
          label: "Device Type",
          type: "Dropdown",
          default: "B",
          selectOptions: ["B", "M", "D"],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "adUnitName",
          label: "Ad Unit Name",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
          style: { width: "60%" },
        },
        {
          name: "adId",
          label: "Div Id",
          type: "AutoComplete",
          default: "",
          addCustom: true,
          selectOptions: [
            "div-ub-1",
            "ub-anchor",
            "ub-top-anchor",
            "ub-popup",
            "ub-inimage",
            "ub-left-ad",
            "ub-right-ad",
            "ub-balloon",
            "ub-interstitial",
            "ub-parallax",
            "ub-parallax1",
            "ub-parallax2",
            "ub-parallax3",
            "ub-siderail",
            "ub-siderail_1",
            "ub-siderail_2",
            "ub-siderail_3",
            "ub-rewarded"
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "sizesM",
          label: "Mobile Sizes",
          type: "DropdownMulti",
          default: "",
          selectOptions: [
            "[120,240]",
            "[160, 600]",
            "[120, 600]",
            "[200,200]",
            "[234,60]",
            "[240,400]",
            "[250,250]",
            "[300,50]",
            "[300,75]",
            "[300,90]",
            "[300,100]",
            "[300,250]",
            "[300,300]",
            "[300,480]",
            "[300,600]",
            "[320,50]",
            "[320,90]",
            "[320,100]",
            "[320,240]",
            "[320,250]",
            "[320,280]",
            "[320,400]",
            "[320,480]",
            "[336,250]",
            "[336,280]",
            "[336,480]",
            "[360,280]",
            "[360,300]",
            "[375,50]",
            "[1,1]",
            "[4,1]",
            "[2,2]",
          ],
          isBool: false,
          isRequired: true,
          saveType: "size_array",
        },
        {
          name: "sizesD",
          label: "Desktop Sizes",
          type: "DropdownMulti",
          default: "",
          selectOptions: [
            "[120,240]",
            "[120,300]",
            "[120,600]",
            "[160,300]",
            "[160,600]",
            "[160,240]",
            "[200,200]",
            "[234,60]",
            "[240,400]",
            "[250,250]",
            "[300,50]",
            "[300,75]",
            "[300,90]",
            "[300,100]",
            "[300,250]",
            "[300,300]",
            "[300,480]",
            "[300,600]",
            "[320,50]",
            "[320,90]",
            "[320,100]",
            "[320,240]",
            "[320,250]",
            "[320,280]",
            "[320,400]",
            "[320,480]",
            "[336,250]",
            "[336,280]",
            "[336,480]",
            "[360,280]",
            "[360,300]",
            "[400,225]",
            "[468,60]",
            "[468,90]",
            "[480,300]",
            "[480,320]",
            "[480,336]",
            "[570,90]",
            "[580,90]",
            "[580,400]",
            "[600,90]",
            "[630,90]",
            "[640,90]",
            "[640,180]",
            "[640,250]",
            "[650,90]",
            "[670,90]",
            "[675,90]",
            "[690,90]",
            "[720, 250]",
            "[720,300]",
            "[728,90]",
            "[728,250]",
            "[728,280]",
            "[728,300]",
            "[729,90]",
            "[750,200]",
            "[768,1024]",
            "[960,90]",
            "[970,90]",
            "[970,250]",
            "[970,300]",
            "[980,90]",
            "[1024,768]",
            "[1200,250]",
            "[1,1]",
            "[4,1]",
            "[2,2]",
          ],
          isBool: false,
          isRequired: true,
          saveType: "size_array",
        },
        {
          name: "bidders",
          label: "BIDDERS",
          type: "objectgroup",
          fields: [
            {
              name: "bidder",
              label: "bidder",
              type: "Dropdown",
              default: "",
              selectOptions: [
                "openx",
                "pubmatic",
                "sovrn",
                "teads",
                "ucfunnel",
                "yandex",
                "fluct"
              ],
              isBool: false,
              isRequired: true,
              saveType: "string",
            },
            {
              name: "Labeltype",
              label: "Label type",
              type: "radio",
              default: "none",
              selectOptions: ["labelAny", "labelAll", "none"],
              isBool: false,
              isRequired: true,
              saveType: "string",
            },
            {
              name: "params",
              label: "params",
              type: "Input",
              default: {},
              selectOptions: {
                openx: ["unit", "delDomain|unibots-d.openx.net"],
                pubmatic: ["publisherId|159448", "adSlot"],
                sovrn: ["tagid"],
                teads: ["placementId", "pageId", "websiteId"],
                ucfunnel: ["adid"],
                yandex: ["pageId", "impId"],
                fluct: ["groupId", "tagId"]
              },
              isBool: false,
              isRequired: true,
              saveType: "objectarray",
            },
          ],
          addButton: true,
          default: [[{}]],
          selectOptions: null,
          isBool: false,
          saveType: "objectarray",
        },
        {
          name: "disabled_bidders",
          label: "Choose bidders to Disable",
          type: "DropdownMulti",
          default: [],
          selectOptions: [
            "adf",
            "openx",
            "pubmatic",
            "sovrn",
            "teads",
            "ucfunnel",
            "yandex",
            "33across",
            "adtelligent",
            "adyoulike",
            "appnexus",
            "emx_digital",
            "nobid",
            "oftmedia",
            "onetag",
            "rhythmone",
            "rubicon",
            "smartadserver",
            "fluct"
          ],
          isBool: false,
          isRequired: false,
        },
        {
          name: "isAP",
          label: "Amazon",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
        {
          name: "refreshMaxLimit",
          label: "Refresh Max Limit",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "number",
        },
        {
          name: "refreshTimeout",
          label: "Refresh Max Timeout",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "number",
        },
        {
          name: "mgid",
          label: "Mgid Passback",
          type: "objectgroup",
          default: {},
          index: "static",
          fields: [
            {
              name: "adId",
              label: "Ad Id",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "src",
              label: "Source",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
          ],
          isRequired: false,
          addButton: false,
        },
        {
          name: "targetDiv",
          label: "Custom Div Targeting",
          type: "objectgroup",
          default: {},
          index: "static",
          fields: [
            {
              name: "elmType",
              label: "elmType",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "selector",
              label: "selector",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "childElm",
              label: "childElm",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "childNum",
              label: "childNum",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              isNum: true,
              saveType: "number",
            },
          ],
          isRequired: false,
          addButton: false,
        },
        {
          name: "ub_adSense",
          label: "AdSense Passback",
          type: "objectgroup",
          default: {},
          index: "static",
          fields: [
            {
              name: "data_adslot",
              label: "data adslot",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
          ],
          isRequired: false,
          addButton: false,
        },
        {
          name: "include_homepage",
          label: "Include Homepage",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
          saveType: "array",
        },
        {
          name: "include_category",
          label: "Include Category",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
          saveType: "array",
        },
        {
          name: "exclude_homepage",
          label: "Exclude Homepage",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
          saveType: "array",
        },
        {
          name: "exclude_category",
          label: "Exclude Category",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
          saveType: "array",
        },
        {
          name: "ad_RequestType",
          label: "Ad RequestType",
          type: "radio",
          default: "new",
          selectOptions: ["new", "old"],
        },

        {
          name: "ub_ClmbParams",
          label: "Clmb Passback",
          type: "objectgroup",
          fields: [
            {
              name: "ub_Clmbenable",
              label: "ub_Clmbenable",
              type: "switch",
              default: false,
              isBool: true,
              isRequired: true,
            },
            {
              name: "ub_Dslotvalue",
              label: "ub_Dslotvalue",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              isRequired: true,
              saveType: "string",
            },
            {
              name: "ub_Mslotvalue",
              label: "ub_Mslotvalue",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              isRequired: true,
              saveType: "string",
            },
            {
              name: "ub_urlclmb",
              label: "ub_urlclmb",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              isRequired: true,
              saveType: "string",
            },
          ],
          default: [[{}]],
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
        {
          name: "childwidth_classname",
          label: "Childwidth Classname",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
          saveType: "string",
        },
        {
          name: "disabled",
          label: "Disable Adunit",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
        {
          name: "refresh",
          label: "Refresh Adunit",
          type: "switch",
          default: true,
          isBool: true,
          isRequired: true,
        },
      ],
      addButton: false,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "mappings_extra_units_config",
      label: "MAPPINGS EXTRA UNITS CONFIG",
      type: "objectgroup",
      fields: [
        {
          name: "adUnitNames",
          label: "adUnitNames",
          type: "Input",
          default: "",
          selectOptions: "",
          isBool: false,
          isRequired: false,
          isTextArea: true,
          saveType: "textarea_array",
        },
      ],
      addButton: false,
      default: [],
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
  ],
};

export default productAdditionalFields;
